import { captureException } from "@sentry/nextjs";
import { Drawer } from "components/Drawer/Drawer";
import { Icon } from "components/Icon";
import { general } from "config/config";
import usePath from "hooks/usePath";
import useUser from "hooks/useUser";
import { t } from "locales";
import Head from "next/head";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { GROUP_IDS, ITicket } from "data/contentData/api/ticket";
import { useCreateTicket } from "data/contentData/hooks/ticket.hooks";
import { ErrorSnackbar } from "components/ErrorSnackbar";
function getReCaptchaToken(action = "homepage") {
  try {
    return window.grecaptcha.execute(general.recaptchaId, {
      action
    });
  } catch (error) {
    captureException(error);
  }
}
const defaultTicket: ITicket = {
  body: "",
  email: "",
  groupId: general.feedback.defaultValue as keyof typeof GROUP_IDS,
  name: "",
  subject: "",
  url: ""
};
export default function Feedback() {
  const {
    authenticated,
    user
  } = useUser();
  const [ticket, setTicket] = useState(defaultTicket);
  const router = useRouter();
  const path = usePath();
  const showFeedback = Boolean(router.query["show-feedback"]);
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [open, setOpen] = useState(showFeedback);
  useEffect(() => {
    if (showFeedback) {
      setOpen(true);
    }
  }, [showFeedback]);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    if (router.query["show-feedback"]) {
      const {
        "show-feedback": _showFeedback,
        ...queryParams
      } = router.query;
      router.push({
        query: {
          ...queryParams
        }
      }, path + sanitizeSearchParams(router.asPath), {
        shallow: true
      });
    }
  };
  const handleChange = ({
    target: {
      value,
      name
    }
  }: ChangeEvent<HTMLInputElement>) => {
    setTicket(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const {
    enqueueSnackbar
  } = useSnackbar();
  const {
    mutate: addTicket,
    isLoading
  } = useCreateTicket();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const token = await getReCaptchaToken("ticket");
    if (!token) {
      enqueueSnackbar(<ErrorSnackbar />, {
        variant: "error",
        persist: true
      });
      return;
    }
    const newTicket: ITicket = {
      ...ticket,
      subject: `${t["feedback"][GROUP_IDS[ticket.groupId]]}: ${ticket.subject}`,
      url: window.location.href
    };
    if (user) {
      newTicket.name = user.name;
      newTicket.email = user.email;
    }
    addTicket(newTicket, {
      onError: () => {
        enqueueSnackbar(<ErrorSnackbar />, {
          variant: "error",
          persist: true
        });
      },
      onSuccess: () => {
        enqueueSnackbar(t["feedback"]["ticket-sent"], {
          variant: "info"
        });
        setTicket(defaultTicket);
        handleClose();
      }
    });
  };
  return <>
            <Tooltip title={t.header.feedback} data-sentry-element="Tooltip" data-sentry-source-file="Feedback.tsx">
                <IconButton ref={anchorEl} onClick={handleOpen} aria-label={t.header.feedback} color="inherit" size="large" data-sentry-element="IconButton" data-sentry-source-file="Feedback.tsx">
                    <Icon name="feedback" data-sentry-element="Icon" data-sentry-source-file="Feedback.tsx" />
                </IconButton>
            </Tooltip>
            <Drawer header={<Typography align="center" variant="subtitle2">
                        {t.feedback.title}
                    </Typography>} position="right" open={open} onClose={handleClose} data-sentry-element="Drawer" data-sentry-source-file="Feedback.tsx">
                <StyledForm onSubmit={handleSubmit} data-sentry-element="StyledForm" data-sentry-source-file="Feedback.tsx">
                    <Head data-sentry-element="Head" data-sentry-source-file="Feedback.tsx">
                        <script async src={`https://www.google.com/recaptcha/api.js?render=${general.recaptchaId}`} />
                    </Head>
                    <Grid container direction="column" spacing={2} data-sentry-element="Grid" data-sentry-source-file="Feedback.tsx">
                        {authenticated ? null : <>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="name" label={t.feedback.name} name="name" onChange={handleChange} value={ticket.name} variant="outlined" required />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="email" label={t.feedback.email} name="email" onChange={handleChange} type="email" value={ticket.email} variant="outlined" required />
                                </Grid>
                            </>}

                        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="Feedback.tsx">
                            <TextField fullWidth select id="demo-simple-select-outlined" label={t.feedback.groupId} name="groupId" onChange={handleChange} value={ticket.groupId} defaultValue={general.feedback.defaultValue} variant="outlined" data-sentry-element="TextField" data-sentry-source-file="Feedback.tsx">
                                {Object.entries(GROUP_IDS).map(([id, translationKey]) => <MenuItem key={id} value={id}>
                                            {t["feedback"][translationKey]}
                                        </MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="Feedback.tsx">
                            <TextField fullWidth id="subject" label={t.feedback.subject} name="subject" onChange={handleChange} value={ticket.subject} variant="outlined" required data-sentry-element="TextField" data-sentry-source-file="Feedback.tsx" />
                        </Grid>
                        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="Feedback.tsx">
                            <TextField fullWidth multiline id="body" label={t.feedback.body} name="body" onChange={handleChange} rows={4} value={ticket.body} variant="outlined" data-sentry-element="TextField" data-sentry-source-file="Feedback.tsx" />
                        </Grid>
                        <Grid container item justifyContent="end" xs={12} data-sentry-element="Grid" data-sentry-source-file="Feedback.tsx">
                            <Button disabled={isLoading} type="submit" variant="filled" data-sentry-element="Button" data-sentry-source-file="Feedback.tsx">
                                {t.feedback.send}
                            </Button>
                        </Grid>
                    </Grid>
                </StyledForm>
            </Drawer>
        </>;
}
const StyledForm = styled("form")(({
  theme
}) => ({
  padding: theme.spacing(4, 2)
}));
function sanitizeSearchParams(asPath: string) {
  if (asPath === "") return "";
  const fullURL = new URL(general.appHost + asPath);
  const searchParams = new URLSearchParams(fullURL.search);
  searchParams.delete("show-feedback");
  return searchParams.toString().length > 0 ? `?${searchParams}` : "";
}