import { AccessTokenResponseType, AuthConfigType, IProduct } from "auth"
import { auth, general } from "config/config"
import { add } from "date-fns"
import { JWT } from "next-auth/jwt"
import IDS4, { IDS4Profile } from "next-auth/providers/identity-server4"

import {
    ISubscription,
    getClaim,
    getSubscriptions
} from "data/customerManager/api/customer"

/**
 * Access token url for server side requests
 */
const accessTokenUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/token`

/**
 * Edit profile url - used to navigate user to edit profile page
 */
const editProfileUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/profile?returnUrl=${general.appHost}&clientId=${auth.clientId}`

/**
 * End session url - used to log user out from identity server
 */
const endSessionUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/endsession`

/**
 * IdentityServer4 provider setup for next-auth
 */
const provider = IDS4({
    clientId: auth.clientId,
    clientSecret: process.env.AUTH_CLIENT_SECRET,
    issuer: process.env.NEXT_PUBLIC_AUTH_ISSUER,
    httpOptions: {
        timeout: 5000
    },
    idToken: true,
    authorization: {
        params: {
            scope: "openid offline_access contentdata-api handbook customer-manager-api chatbot-api",
            allowIpLogin: true,
            ipRequiredRole: "Handbook.Subscriber"
        }
    },
    profile(p) {
        const profile = p as IDS4Profile
        const name =
            profile["http://nhi.no/Events/Customer/Fullname"] ??
            profile[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
            ]

        const roleOrRoles =
            profile[
                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ] ?? []
        const roles = (
            Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles]
        ).filter(role => role.startsWith("Handbook."))

        const concurrency = parseInt(
            profile["http://nhi.no/Events/Customer/HandbookConcurrency"] ||
                auth.defaultMaxConcurrentLogins.toString(),
            10
        )

        return {
            id: profile.sub ?? "",
            guid: profile["http://nhi.no/Events/Customer/CustomerGuid"] ?? "",
            parentId: profile["http://nhi.no/Events/Customer/ParentGuid"],
            email: profile.email,
            organisationAffiliation:
                profile[
                    "http://nhi.no/Events/Customer/OrganisationAffiliation"
                ],
            professionName:
                profile["http://nhi.no/Events/Customer/ProfessionName"] ?? "",
            name,
            concurrency,
            roles,
            products: []
        }
    },
    checks: ["pkce"]
})

/**
 * Get JWT to authenticate requests from frontend-backend to supported services
 * @returns access token and expiration time
 */
async function getServerSideAccessToken(): Promise<AccessTokenResponseType> {
    const url = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/connect/token`

    const response = await fetch(url, {
        body: new URLSearchParams({
            client_id: "next-handbook-server",
            client_secret: process.env.IDS_SERVER_SIDE_SECRET,
            grant_type: "client_credentials",
            scope: ""
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post"
    })

    const accessToken: AccessTokenResponseType = await response.json()

    if (response.ok) {
        return {
            ...accessToken,
            // Give a 10 sec buffer
            expires_in: accessToken.expires_in - 10
        }
    }

    throw {
        message: "AccessTokenError",
        accessToken
    }
}

/**
 * Get roles for the user
 * @param token JWT
 * @returns list of roles
 */
async function getRoles(token: JWT) {
    const roleClaims = await getClaim(
        token.user.guid,
        encodeURIComponent(
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        )
    )
    if (!roleClaims) {
        return []
    }

    const roles = token.user?.roles ?? []
    const customerManagerRoles = roleClaims
        .map(p => p.value)
        .filter(
            role => typeof role === "string" && role.startsWith("Handbook.")
        )

    customerManagerRoles.forEach(role => {
        if (!roles.includes(role)) {
            roles.push(role)
        }
    })

    return roles
}

/**
 * Get active CDS products for user
 * @param token JWT
 * @returns list of active products
 */
async function getProducts(token: JWT): Promise<IProduct[]> {
    const isActive = (subscription: ISubscription) => {
        const now = new Date()
        const startDate = new Date(subscription.start)
        const stopDate = !subscription.stop
            ? add(new Date(), { days: 1 })
            : new Date(subscription.stop)

        return startDate <= now && now <= stopDate
    }

    const isCDSProduct = (subscription: ISubscription) => {
        return subscription.product.applicationId === 10
    }

    const guid: string | undefined = token.user?.guid

    if (!guid || guid === "") {
        return []
    }

    const subscriptions = await getSubscriptions(guid)
    if (!subscriptions || subscriptions.length === 0) {
        return []
    }

    const products = subscriptions
        .filter(isActive)
        .filter(isCDSProduct)
        .sort((a, b) => {
            return new Date(b.start).getTime() - new Date(a.start).getTime()
        })
        .map(sub => ({
            id: sub.id.toString(),
            name: sub.product.name,
            productId: sub.product.id,
            start: sub.start
        }))

    if (!products || products.length === 0) {
        return []
    }

    return products
}

export const identityServerConfig: AuthConfigType = {
    provider,
    accessTokenUrl,
    editProfileUrl,
    endSessionUrl,
    getServerSideAccessToken,
    getRoles,
    getProducts
}
