import { Favorites } from "components/Layout/Header/Favorites";
import Feedback from "components/Layout/Header/Feedback";
import Logo from "components/Layout/Header/Logo";
import SearchField from "components/Layout/Header/SearchField";
import Megamenu from "components/Megamenu/Megamenu";
import Profile from "components/ProfilePopover/Profile";
import { useToggleOnScroll } from "hooks/useScrollSpy";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { Assistant } from "./Assistant";
export function Header() {
  const show = useToggleOnScroll();
  return <AppBar elevation={0} position="sticky" className="no-print" data-sentry-element="AppBar" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
            <StyledContainer show={show.toString()} data-sentry-element="StyledContainer" data-sentry-source-file="Header.tsx">
                <Megamenu data-sentry-element="Megamenu" data-sentry-source-file="Header.tsx" />
                <Logo data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
                <StyledSearchInput show={show.toString()} data-sentry-element="StyledSearchInput" data-sentry-source-file="Header.tsx">
                    <SearchField data-sentry-element="SearchField" data-sentry-source-file="Header.tsx" />
                </StyledSearchInput>
                <StyledHeaderMenu data-sentry-element="StyledHeaderMenu" data-sentry-source-file="Header.tsx">
                    <Feedback data-sentry-element="Feedback" data-sentry-source-file="Header.tsx" />
                    <Favorites data-sentry-element="Favorites" data-sentry-source-file="Header.tsx" />
                    <Assistant data-sentry-element="Assistant" data-sentry-source-file="Header.tsx" />
                    <Profile data-sentry-element="Profile" data-sentry-source-file="Header.tsx" />
                </StyledHeaderMenu>
            </StyledContainer>
        </AppBar>;
}
export function HeaderSimple() {
  return <AppBar elevation={0} className="classes.hideOnPrint" position="sticky" data-sentry-element="AppBar" data-sentry-component="HeaderSimple" data-sentry-source-file="Header.tsx">
            <Container fixed disableGutters data-sentry-element="Container" data-sentry-source-file="Header.tsx">
                <StyledContainer show={"false"} data-sentry-element="StyledContainer" data-sentry-source-file="Header.tsx">
                    <Logo simple data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
                </StyledContainer>
            </Container>
        </AppBar>;
}
const StyledContainer = styled("div")<{
  show: string;
}>(({
  show,
  theme
}) => ({
  width: `min(100%, ${theme.breakpoints.values.lg}px)`,
  padding: theme.spacing(0, 2),
  alignItems: "center",
  columnGap: theme.spacing(2),
  display: "grid",
  gridTemplateColumns: "48px 1fr auto auto",
  gridTemplateRows: show === "true" ? "64px 64px" : "64px",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "48px auto 1fr auto",
    gridTemplateRows: "64px",
    margin: "0 auto"
  }
}));
const StyledHeaderMenu = styled("div")(({
  theme
}) => ({
  display: "flex",
  gap: theme.spacing(1),
  [theme.breakpoints.only("xs")]: {
    gridColumn: "3 / 5"
  }
}));
const StyledSearchInput = styled("div")<{
  show: string;
}>(({
  show,
  theme
}) => ({
  display: show === "true" ? "block" : "none",
  gridColumn: "1 / 5",
  gridRow: 2,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
    display: "block",
    gridColumn: 3,
    gridRow: 1
  }
}));