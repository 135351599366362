import { Icon } from "components/Icon";
import { t } from "locales";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
export function ErrorSnackbar() {
  const {
    closeSnackbar
  } = useSnackbar();
  return <StyledSnackbar data-sentry-element="StyledSnackbar" data-sentry-component="ErrorSnackbar" data-sentry-source-file="ErrorSnackbar.tsx">
            <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="ErrorSnackbar.tsx">
                {t["feedback"]["error-sending-ticket"]}
            </Typography>

            <IconButton aria-label={t["close-snackbar"]} color="inherit" onClick={() => closeSnackbar()} size="large" data-sentry-element="IconButton" data-sentry-source-file="ErrorSnackbar.tsx">
                <Icon name="close" fontSize="small" data-sentry-element="Icon" data-sentry-source-file="ErrorSnackbar.tsx" />
            </IconButton>
        </StyledSnackbar>;
}
const StyledSnackbar = styled("div")(({
  theme
}) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2)
}));