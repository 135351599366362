/**
 * Split a name into parts
 * @param name Name to split
 * @param fallback Fallback name if name is empty
 * @returns Object with name, initials, firstName and restOfName
 */
export function nameParts(name?: string, fallback = "") {
    if (!name) {
        name = fallback
    }

    const nameArray = name.split(" ")
    const initials = nameArray.map(part => part[0]).join("")
    const firstName = nameArray[0]
    const restOfName = nameArray.slice(1).join(" ")

    return {
        name,
        initials,
        firstName,
        restOfName
    }
}
