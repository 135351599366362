import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "components/Icon";
import useUser from "hooks/useUser";
import { isEditor } from "auth/authorization";
const IDS_WITH_AI = ["legehandboka-no", "legehandboka-dev", "localhost"];
export function Assistant() {
  const {
    user
  } = useUser();
  const userRoles = user?.roles || [];
  const showButton = isEditor(userRoles) && IDS_WITH_AI.includes(process.env.NEXT_PUBLIC_ID);
  if (!showButton) {
    return null;
  }
  return <Tooltip title={"Assistant"} data-sentry-element="Tooltip" data-sentry-component="Assistant" data-sentry-source-file="Assistant.tsx">
            <IconButton href={"/assistant"} aria-label={"Assistant"} color="inherit" size="large" data-sentry-element="IconButton" data-sentry-source-file="Assistant.tsx">
                <Icon name="memory" data-sentry-element="Icon" data-sentry-source-file="Assistant.tsx" />
            </IconButton>
        </Tooltip>;
}