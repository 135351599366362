import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Icon } from "components/Icon";
import { t } from "locales";
import { createRef, useEffect } from "react";
import { createPortal } from "react-dom";
interface IModalProps {
  isClosed: () => void;
  title: string;
  children: React.ReactNode;
}
export function Modal({
  isClosed,
  title,
  children
}: IModalProps) {
  useEffect(() => {
    function keyListener(event: KeyboardEvent) {
      const listener = keyListenersMap.get(event.key);
      return listener && listener(event);
    }
    document.addEventListener("keydown", keyListener);
    return () => document.removeEventListener("keydown", keyListener);
  });
  const modalRef = createRef<HTMLDivElement>();
  const handleTabKey = (event: KeyboardEvent) => {
    const focusableItems = modalRef.current?.querySelectorAll("a[href], button, textarea, input[type='text'], input[type='radio'], input[type='checkbox'], select");
    if (!focusableItems) {
      return;
    }
    const firstItem = focusableItems[0] as HTMLElement;
    const lastItem = focusableItems[focusableItems.length - 1] as HTMLElement;
    if (!event.shiftKey && document.activeElement !== firstItem) {
      firstItem.focus();
      return event.preventDefault();
    }
    if (event.shiftKey && document.activeElement !== lastItem) {
      lastItem.focus();
      event.preventDefault();
    }
  };
  const keyListenersMap = new Map([["Tab", handleTabKey], ["Escape", isClosed]]);
  return createPortal(<>
            <BackDrop onClick={isClosed} />
            <ModalRoot ref={modalRef} aria-modal="true" aria-labelledby="modal-title" role="dialog" tabIndex={-1}>
                <StyledModal>
                    <ModalHeader>
                        <Typography variant="h4" id="modal-title">
                            {title}
                        </Typography>
                        <CloseButton onClick={isClosed}>
                            <Icon name="close" />
                            <Typography variant="subtitle2">
                                {t["close-popover"]}
                            </Typography>
                        </CloseButton>
                    </ModalHeader>
                    {children}
                </StyledModal>
            </ModalRoot>
        </>, document.body);
}
const BackDrop = styled("div")(({
  theme
}) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: theme.palette.grey[500],
  opacity: 0.5,
  zIndex: theme.zIndex.modal - 1
}));
const ModalRoot = styled("div")(({
  theme
}) => ({
  width: "95vw",
  position: "fixed",
  display: "flex",
  top: "50%",
  left: "50%",
  zIndex: theme.zIndex.modal,
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "70vw"
  }
}));
const StyledModal = styled("div")(({
  theme
}) => ({
  position: "relative",
  backgroundColor: theme.palette.grey.white,
  opacity: 1,
  padding: theme.spacing(0, 2, 2, 2),
  borderRadius: "6px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "80vh",
  top: "20%"
}));
const ModalHeader = styled("div")(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: 0,
  margin: theme.spacing(0, 0, 0.5, 0),
  padding: theme.spacing(2, 2, 0, 2),
  backgroundColor: theme.palette.grey.white,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 0, 0, 0)
  }
}));
const CloseButton = styled("button")(({
  theme
}) => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",
  height: 48,
  gap: theme.spacing(1),
  padding: theme.spacing(0, 2),
  backgroundColor: theme.palette.button.text.background,
  color: theme.palette.button.text.color,
  border: "none",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.button.text.hover
  }
}));